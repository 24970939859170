.recipient-address {
  h4 {
    font-size: 1.2em;
  }
}

#roll-distributions {
  .distributions-header {
    margin-bottom: 1em;
  }
}
.distribution {
  padding-bottom: 1em;
}
.distribution-address {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.distribution-rolls {
  ul {
    padding-left: 0.5em;
    li {
      line-height: 1.5em;
      margin-bottom: 0.5em;
    }
  }
}
.distribution-comment {
  margin-top: 0.5em;
}

.distribution-entry {
  border: 1px solid $darkgrey;
  padding: 0.5em;
}

.distribution-tools {
  text-align: right;
  margin-top: 0.2em;
}

.epic {
  ul {
    margin: 0.5rem 0 0 0.5rem;
    list-style: disc;
    list-style-position: outside;
    line-height: 1rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
}

.distributionAnnotation{
  padding: 0;
  margin: 5px 0px 0 5px;
  float: left;
  button.open-editor{
    padding: 0;
    border: 0;
    text-align: center;
    display: inline-block;
    background-color: transparent;
    cursor: pointer;
    height: 2rem;
  }
  button.inline-editor{
    margin: 0;
    height: 1rem;
  }
  
  .annotationEditor{
    position: relative;
    left: 10px;
  }
  
  
}
