.login .warning {
	color: $warning;
}

.profile h2,
.client-confirm h2,
.password-reset-request h2,
.password-reset-confirm h2,
.password-expired-request h2 {
	padding-top: 20px;
	padding-bottom: 10px;
}

.profile__account p {
	line-height: 1.5;
}

.profile .button,
.register .button,
.client-confirm .button,
.password-reset-request .button,
.password-reset-confirm .button,
.password-expired-request .button {
	margin-left: 0px;
	margin-right: 40px;
}

.form-error {
	font-weight: bold;
	color: red;
}

.email-confirm .failed {
	color: red;
}

.client-confirm .failed {
	color: red;
}

.company-profile-container,
.company-users-container {
	padding: 20px;
	border: 1px solid #fff;
	overflow-x: hidden;
	overflow-y: auto;
}
.company-users-container {
	height: 200px;
}

.company-users-checkbox-label {
	display: inline-block;
	padding-left: 2.5rem;
	text-indent: -2.5rem;
	line-height: 1.5rem;
}

.company-users-checkbox-label--disabled {
	color: #666;
}

.company-users-checkbox-input {
	width: 1.5rem;
	height: 1.5rem;
	min-height: 1.5rem;
	line-height: 1.5rem;
	margin-right: 0.5rem;
	vertical-align: middle;
	position: relative;
}
