.label-input {
  margin: 0px 0px;
  width: 75px;
  display: inline-block;
}

table {
  min-width: 300px;
  width: 100%;
  border-top: none;
  border-bottom: none;
}

thead {
  em {
    font-style: normal;
    text-transform: uppercase;
  }
}

tbody {
  border-top: 2px solid $primary;
  border-bottom: 2px solid $primary;

  .blocked {
    color: red;
  }

  tr:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  tr:nth-child(even) {
    td > .cell:first-of-type {
      background: $darkgrey;
      padding: 5px;
    }
  }
  tr:nth-child(odd) {
    td > .cell:first-of-type {
      background: $lightgrey;
      padding: 5px;
    }
  }

  td {
    padding: 0px 1px 2px 0px;
    > div {
      height: 50px;
    }
  }
}

.cell {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 1px;
  padding-left: 20px;
}
.tools-cell {
  min-width: 60px !important;
  text-align: center;
}

// media queries
@include mq($until: md) {
  .cell {
    min-width: 120px !important;
  }

  .table {
    overflow-x: scroll;
    margin-right: -15px;
    border-top: 2px solid $primary;
    border-bottom: 2px solid $primary;
  }

  .table:before {
    content: "";
    height: 379px;
    position: absolute;
    display: block;
    right: 0px;
    width: 40px;
  }

  thead {
    position: absolute;
    background: $background;

    th {
      text-align: left;
      display: block;
      height: 54px;
      padding: 20px 10px 10px 0px;
    }
  }

  tbody {
    float: left;
    padding-left: 150px;
    border-top: none !important;
    border-bottom: none !important;

    tr {
      display: table-cell;
    }
    td {
      min-width: 50px;
      float: left;
      clear: both;
    }
  }
}

.open-modal {
  min-width: 30px;
}

// validation label colours and errors

.icon-error {
  color: $error;
  font-size: 60px;
  font-weight: bold;
}

.error-modal-button {
  position: relative;
  display: block;
  margin-top: -40px;
  margin-left: -20px;
  left: 90px;
  cursor: pointer;
}

.error-modal-cell-button {
  display: block;
  margin-top: -42px;
  margin-left: 27px;
}

.status {
  width: 10px;
  content: "";
  float: left;
  height: 50px;
  margin-top: -5px;
  margin-left: -5px;
  margin-right: 5px;
}

.status--activated {
  background: $activated;
}
.status--delivery {
  background: $delivery;
}
.status--shipment {
  background: $shipment;
}
.status--received {
  background: $received;
}
.status--pending {
  background: $pending;
}
.status--error {
  background: $error;
}
.status--rejected {
  background: $error;
}
.status--valid {
  background: $green;
}

// order statistics
.status--CREATED {
  background: $pending;
}
.status--ORDER_PENDING {
  background: $pending;
}
.status--ORDER_RECEIVED {
  background: $received;
}
.status--PARTIALLY_SHIPPED {
  background: $shipment;
}
.status--SHIPMENT {
  background: $shipment;
}
.status--DELIVERY_RECEIVED {
  background: $delivery;
}
.status--ACTIVATED {
  background: $activated;
}
.status--PARTIALLY_ACTIVATED {
  background: $partially_activated;
}
.status--CANCEL_REQUESTED {
  background: $error;
}
.status--ORDER_CANCELLED {
  background: $error;
}
