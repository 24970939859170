@import "./authentications/Map/maps.scss";

.statistics-landing-box {
  h1,
  h2,
  h3,
  h4 {
    text-transform: uppercase;
  }
  ul {
    list-style-type: none;

    li a {
      text-transform: uppercase;
    }
    li a svg {
      margin-right: 0.5rem;
    }
  }
}

.statistics {
  clear: both;
  h1 {
    text-transform: uppercase;
  }
}

.statistics-content {
}
.statistics-footer {
  clear: both;
}
.AutoSizerWrapper {
  flex: 1 1 auto;
}

.sort-header {
  span {
    display: inline;
    font-size: 0.75rem;
  }
}

.query-params {
  clear: both;
  float: none;
}
.query-param {
  float: left;
  label {
    display: block;
    float: left;
  }
}
// make sure, that the select-fields popup over the map
.react-datepicker-popper {
  z-index: 1000;
}
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker__input-container {
  input {
    min-height: 49px;
    height: 49px;
  }
}
.download-button {
  padding-right: 0.5rem;
  margin: 0;
  line-height: 3.2rem;
  min-height: 3.2rem;
  float: right;
  .button {
    padding: 0;
    margin: 0;
  }
}

.statistics-table {
  clear: both;
  padding-top: 1em;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 25px;
}

.detail-view-link {
  text-decoration: underline;
  cursor: pointer;
}

.filter-companies {
  width: 500px;
  height: 300px;
  overflow: auto;
  z-index: 999;
  position: absolute;
  left: 150px;
  float: left;

  font-size: 1rem;
  font-style: normal;

  input:focus {
    outline-color: none;
    border-color: none;
    box-shadow: none;
  }
}

.ReactVirtualized__Table {
  .cell {
    padding-left: 0;
  }
}
.ReactVirtualized__Table__headerRow {
  border-bottom: 2px solid $primary;
  text-transform: uppercase;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  .icon {
    margin-left: 0.5rem;
    margin-top: -0.5rem;
  }
}
.ReactVirtualized__Table__row {
}
.ReactVirtualized__Table__rowColumn {
  padding: 0.3rem 0 0.3rem 0;
}
.ReactVirtualized__Table__row:nth-of-type(odd) {
  background-color: $darkgrey;
}

.quota_group {
  clear: both;
  margin-bottom: 60px;
  .badge {
    float: left;
    width: 16em;
    background-color: white;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-right: 0.2em;
    border: 1px solid $bordergrey;
    .badge__icon {
      float: left;
      height: 30px;
      width: 60px;
      margin-right: 10px;
    }
    .red {
      color: red;
    }
    .green {
      color: green;
    }
    .datum {
      float: left;
      height: 40px;
      .amount {
        font-size: 1.5em;
        width: 150px;
        text-align: center;
        display: block;
      }
      .legend {
        font-size: 1em;
        width: 150px;
        text-align: center;
        display: block;
        padding: 5px 0px 10px 0px;
      }
    }
  }
}

table.keyValueList {
  margin-top: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 1em;
  td.key {
    font-weight: bold;
  }
  td.value {
    font-weight: normal;
  }
}