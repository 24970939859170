/* Colors */

$primary: #0b0b3b;
$secondary: #ffffff;
$focusedinput: #ff9e00;

$background: #cccccc;

$lightgrey: #eaeaea;
$darkgrey: #b5b5b5;
$darkergrey: #333333;
$bordergrey: #999999;

$valid: #00ffff;
$error: #ff3300;
$warning: #ff9e00;

$pending: #666666;
$received: #ff00ff;
$shipment: #ffff00;
$delivery: #00ffff;
$partially_activated: #e8a006;
$activated: #66ff99;

$winered: #db0b3b;

$green: $activated;
$red: $error;
