.modal__overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__content {
  position: relative;
  overflow-y: auto;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  border: 1px solid #0b0b3b;
  background: white;
  border-radius: $global-radius;
  outline: none;
  padding: 20px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
}

.modal__content--alert {
  position: relative;
  overflow-y: auto;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  border: 3px solid $error;
  background: white;
  border-radius: $global-radius;
  outline: none;
  padding: 20px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.75);
}

@include mq($from: md) {
  .modal__content,
  .modal__content--alert {
    width: 70%;
  }
}

.modal-nav-side {
  display: block;

  margin: 0 auto;
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  transform: none;
  outline: none;

  .nav-language__trigger {
    max-width: 120px;
  }

  .nav__item {
    a {
      color: white;
      font-size: 2rem;
      padding: 10px 0px;
    }

    li {
      font-size: 2rem;
    }
    border-bottom: 1px dashed rgba($lightgrey, 0.5);
  }

  .nav__item:first-of-type {
    border-top: 1px solid rgba($lightgrey, 0.5);
    color: white;
    border-bottom: 1px dashed rgba($lightgrey, 0.5);
  }

  .nav__item:last-of-type {
    color: white;
    border-bottom: 1px solid rgba($lightgrey, 0.5);
  }

  .nav-side {
    float: left;
    background: rgba($primary, 1);
    width: 66vmin;
    height: 100vh;
    padding: 0px 30px;
  }
}

/* Remove scroll on the body when react-modal is open */
.modal__body--open {
  overflow: hidden;
}

.modal-close {
  background: url("./assets/icons/icon-close.svg");
  display: block;
  float: right;
  position: relative;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.modal--image img {
  max-height: 640px;
}

.modal--alert {
  background: white;
  padding: 50px;
  font-weight: bold;
  text-align: center;
  color: $error;
}
