/* header
*/

header {
  // for "calc" see https://sass-lang.com/documentation/breaking-changes/slash-div/
  padding-top: calc($vertical-rythm / 2);
  padding-bottom: $vertical-rythm;
}

.logo-claim {
  height: 109px;
}

.logo {
  width: 15rem;
  height: 6rem;
  background: url("./assets/images/stellantis-logo.svg");
  background-size: cover;
}

.claim {
  // for "calc" see https://sass-lang.com/documentation/breaking-changes/slash-div/
  padding-left: calc($vertical-rythm/2);
  padding-top: 1.5rem;
  display: table;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5rem;
}

.claim-firstline {
  font-size: 1.3rem;
}

/* Navs
*/

.nav {
  & a {
    text-decoration: none;
    text-transform: uppercase;
  }
}

// nav-main icons
.icon-welcome:before {
  background: url(./assets/icons/icon-home.svg);
}
.icon-statistics:before {
  background: url(./assets/icons/icon-statistics.svg);
}
.icon-order:before {
  background: url(./assets/icons/icon-order.svg);
}
.icon-profile:before {
  background: url(./assets/icons/icon-profile.svg);
}
.icon-information:before {
  background: url(./assets/icons/icon-manual.svg);
}
.icon-contact:before {
  background: url(./assets/icons/icon-contact.svg);
}

/** TODO address-list still uses a dummy-icon */
.icon-address-list:before {
  background: url(./assets/icons/icon-address-list.svg);
}
.icon-redistribution:before {
  background: url(./assets/icons/icon-redistribution.svg);
}

// nav-side icons
.icon-tcnc:before {
  background: url(./assets/icons/icon-tcnc.svg);
}
.icon-manual:before {
  background: url(./assets/icons/icon-manual.svg);
}
.icon-languages:before {
  background: url(./assets/icons/icon-language.svg);
}
.icon-logout:before {
  background: url(./assets/icons/icon-login-logout.svg);
}

.nav-icon:before {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  height: 24px;
  width: 24px;
  content: "";
  background-size: cover;
}

@include mq($until: sm) {
  .nav-icon:before {
    display: none;
  }
}

// nav-side hamburger
.hamburger {
  position: fixed;
  top: 0px;
  right: 0px;
  padding-left: 1.25em;
  z-index: 500;
}

.hamburger:before {
  content: "";
  position: absolute;
  top: 12px;
  right: 15px;
  width: 30px;
  height: 6px;
  padding-top: 6px;
  border-top: 18px double #000;
  border-bottom: 6px solid #000;
}

.nav-main {
  position: relative;
  z-index: 10;
  padding-top: 15px;
  li {
    font-size: 0.75rem;
    margin-left: 5px;
    margin-right: 10px;
  }
}

.nav__item {
  cursor: pointer;
  margin-right: 5px;
  span.nav-label {
    display: inline;
    vertical-align: middle;
    margin-left: 5px;
  }
}

.nav__item--active {
  display: block;
  li {
    display: block;
    position: relative;
    padding-bottom: 16px;
    border-bottom: 5px solid #db0b3b;
  }
}

.nav-main-hr {
  margin: 0;
  position: relative;
  width: 100%;
  z-index: -1;
  top: -6px;
}

.nav-side-container {
  position: absolute;
  top: 3.2rem;
  right: 0px;
}

.nav-side {
  .dropdown {
    .dropdown__trigger {
      overflow: hidden;
    }
    .dropdown__content {
      opacity: 0;
      height: 0px;
    }
  }

  .dropdown--active {
    .dropdown__trigger {
      overflow: visible;
    }
    .dropdown__content {
      opacity: 1;
      pointer-events: all;
      height: auto;
      z-index: 1000;
    }
  }

  li {
    font-size: 0.8rem;
    margin-left: 0px;
    margin-right: 15px;
  }
}

@include mq($until: sm) {
  .nav-side {
    .dropdown {
      .dropdown__trigger {
        overflow: hidden;
      }
      .dropdown__content {
        opacity: 0;
        height: 0px;
      }
    }

    .dropdown--active {
      .dropdown__trigger {
        overflow: visible;
      }
      .dropdown__content {
        opacity: 1;
        pointer-events: all;
        height: auto;
        z-index: 1000;
      }
    }
  }
}

@include mq($until: sm) {
  .nav {
    padding-top: 40px;
  }

  .nav-main {
    padding-top: 0px;
    height: auto;

    li {
      font-size: 1rem;
    }

    .dropdown {
      display: inline-block;
    }

    .dropdown__trigger {
      overflow: hidden;
      position: relative;
      height: 42px;
      min-width: 150px;
      display: block;
    }

    .dropdown__content {
      pointer-events: none;
      position: absolute;
      display: block;
      min-width: 150px;
      height: 42px;
      line-height: 42px;
      border: 1px solid $primary;
      border-radius: $global-radius;
    }

    .dropdown--active .dropdown__content {
      display: block;
    }

    .dropdown--active {
      .dropdown__trigger {
        overflow: visible;
      }
      .dropdown__content {
        pointer-events: all;
        height: auto;
        background: $background;
        z-index: 1000;
      }
    }
  }

  .nav__item {
    float: none;
  }

  .nav__item--active {
    li {
      color: $secondary;
      position: absolute;
      top: 0px;
      border: none;
      padding-bottom: 0px;
      pointer-events: none;
    }
  }
}

.language-switch {
  display: table;
  background: $secondary;
  border-top: 1px solid $primary;
}

@include mq($until: sm) {
  .language-switch {
    background: transparent;
  }
}

.nav-language__trigger {
  max-width: 100px;
}
