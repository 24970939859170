.terms-of-use {
	background-color: $secondary;
	color: $primary;
	padding: 2rem;
	border-radius: $global-radius;
	@include mq($from: md) {
		padding: 5rem;
	}

	p {
		line-height: 1.5rem;
		padding-top: 1rem;
	}
	h2,
	h3 {
		padding-top: 2rem;
	}

	ol {
		padding-left: 1.5rem;
		list-style-position: outside;
		padding-top: 1rem;
		line-height: 1.5rem;
	}

	ol.lower-alpha {
		list-style-type: lower-alpha;
	}
}
