@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/Open-Sans-300.eot");
  src: url("../assets/fonts/Open-Sans-300.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Light"), local("Open-Sans-300"),
    url("../assets/fonts/Open-Sans-300.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-300.woff") format("woff"),
    url("../assets/fonts/Open-Sans-300.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-300.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/Open-Sans-regular.eot");
  src: url("../assets/fonts/Open-Sans-regular.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans"), local("Open-Sans-regular"),
    url("../assets/fonts/Open-Sans-regular.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-regular.woff") format("woff"),
    url("../assets/fonts/Open-Sans-regular.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-regular.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  src: url("../assets/fonts/Open-Sans-600.eot");
  src: url("../assets/fonts/Open-Sans-600.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Semibold"), local("Open-Sans-600"),
    url("../assets/fonts/Open-Sans-600.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-600.woff") format("woff"),
    url("../assets/fonts/Open-Sans-600.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-600.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/Open-Sans-700.eot");
  src: url("../assets/fonts/Open-Sans-700.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Bold"), local("Open-Sans-700"),
    url("../assets/fonts/Open-Sans-700.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-700.woff") format("woff"),
    url("../assets/fonts/Open-Sans-700.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-700.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  font-style: normal;
  src: url("../assets/fonts/Open-Sans-800.eot");
  src: url("../assets/fonts/Open-Sans-800.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Extrabold"), local("Open-Sans-800"),
    url("../assets/fonts/Open-Sans-800.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-800.woff") format("woff"),
    url("../assets/fonts/Open-Sans-800.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-800.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: italic;
  src: url("../assets/fonts/Open-Sans-300italic.eot");
  src: url("../assets/fonts/Open-Sans-300italic.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Light Italic"), local("Open-Sans-300italic"),
    url("../assets/fonts/Open-Sans-300italic.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-300italic.woff") format("woff"),
    url("../assets/fonts/Open-Sans-300italic.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-300italic.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: italic;
  src: url("../assets/fonts/Open-Sans-italic.eot");
  src: url("../assets/fonts/Open-Sans-italic.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Italic"), local("Open-Sans-italic"),
    url("../assets/fonts/Open-Sans-italic.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-italic.woff") format("woff"),
    url("../assets/fonts/Open-Sans-italic.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-italic.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: italic;
  src: url("../assets/fonts/Open-Sans-600italic.eot");
  src: url("../assets/fonts/Open-Sans-600italic.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Semibold Italic"), local("Open-Sans-600italic"),
    url("../assets/fonts/Open-Sans-600italic.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-600italic.woff") format("woff"),
    url("../assets/fonts/Open-Sans-600italic.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-600italic.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: italic;
  src: url("../assets/fonts/Open-Sans-700italic.eot");
  src: url("../assets/fonts/Open-Sans-700italic.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Bold Italic"), local("Open-Sans-700italic"),
    url("../assets/fonts/Open-Sans-700italic.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-700italic.woff") format("woff"),
    url("../assets/fonts/Open-Sans-700italic.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-700italic.svg#OpenSans") format("svg");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  font-style: italic;
  src: url("../assets/fonts/Open-Sans-800italic.eot");
  src: url("../assets/fonts/Open-Sans-800italic.eot?#iefix")
      format("embedded-opentype"),
    local("Open Sans Extrabold Italic"), local("Open-Sans-800italic"),
    url("../assets/fonts/Open-Sans-800italic.woff2") format("woff2"),
    url("../assets/fonts/Open-Sans-800italic.woff") format("woff"),
    url("../assets/fonts/Open-Sans-800italic.ttf") format("truetype"),
    url("../assets/fonts/Open-Sans-800italic.svg#OpenSans") format("svg");
}

/**
 * liberation-sans: used for label-printing
 */

@font-face {
  font-family: "Liberation Sans";
  src: url("../assets/fonts/LiberationSans.woff2") format("woff2"),
    url("../assets/fonts/LiberationSans.woff") format("woff"),
    url("../assets/fonts/LiberationSans.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Liberation Sans Italic";
  src: url("../assets/fonts/LiberationSans-Italic.woff2") format("woff2"),
    url("../assets/fonts/LiberationSans-Italic.woff") format("woff"),
    url("../assets/fonts/LiberationSans-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Liberation Sans Bold";
  src: url("../assets/fonts/LiberationSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/LiberationSans-Bold.woff") format("woff"),
    url("../assets/fonts/LiberationSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Liberation Sans Bold Italic";
  src: url("../assets/fonts/LiberationSans-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/LiberationSans-BoldItalic.woff") format("woff"),
    url("../assets/fonts/LiberationSans-BoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Liberation Sans Narrow Bold";
  src: url("../assets/fonts/LiberationSansNarrow-Bold.woff2") format("woff2"),
    url("../assets/fonts/LiberationSansNarrow-Bold.woff") format("woff"),
    url("../assets/fonts/LiberationSansNarrow-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Liberation Sans Narrow Italic";
  src: url("../assets/fonts/LiberationSansNarrow-Italic.woff2") format("woff2"),
    url("../assets/fonts/LiberationSansNarrow-Italic.woff") format("woff"),
    url("../assets/fonts/LiberationSansNarrow-Italic.ttf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Liberation Sans Narrow";
  src: url("../assets/fonts/LiberationSansNarrow-Regular.woff2") format("woff2"),
    url("../assets/fonts/LiberationSansNarrow-Regular.woff") format("woff"),
    url("../assets/fonts/LiberationSansNarrow-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
