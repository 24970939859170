button.detail-view-link {
  border: 0;
  background: transparent;
  text-align: center;
  padding: 1em;
  margin:0;
}

button.edit-link{
  border: 0;
  background: transparent;
  text-align: center;
  padding: 0;
  margin:0;
  cursor: pointer; 
}
button.edit-link:hover{
  text-decoration: underline;
}

.pagination {
  display: table-row-group;
  border-bottom: none;
  a {
    float: left;
    margin-top: 10px;
    padding: 8px 16px;
  }

  a.current-page {
    background-color: $primary;
    color: $background;
    border-radius: $global-radius;
  }

  a:hover:not(.current-page) {
    background-color: $secondary;
    border-radius: $global-radius;
  }
}

.button-spinner {
  border: 3px solid $bordergrey;
  border-radius: 50%;
  border-top: 3px solid $primary;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}