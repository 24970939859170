.icon-warning {
  color: $error;
}

.large-button-spinner {
    border: 4px solid $bordergrey;
    border-radius: 50%;
    border-top: 4px solid $primary;
    width: 20px;
    height: 20px;
    -webkit-animation: large-spin 1s linear infinite; /* Safari */
    animation: large-spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes large-spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes large-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }