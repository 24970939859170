// CSS framework PSA
// Currently, it is a strange mix of inuitcss, sass-mq (media queries), susy (grid layout).
// It does not use inuitcss node module but local copy ...
// Some ideas from ITCSS (through inuitcss) - BUT, some in parts chaotic ...

$vertical-rythm: 30px;
$global-radius: 6px;

// High-level base settings.
$inuit-base-font-size: 16px !default;
$inuit-base-line-height: 30px !default;
$gutter: 30px;

// Namespace.
$inuit-namespace: "";
$namespace: $inuit-namespace;

$mq-breakpoints: (
  xs: 320px,
  sm: 768px,
  md: 970px,
  lg: 1200px,
  xl: 1920px,
  xxl: 3840px
);

@import "settings/settings.core";
@import "settings/settings.colors";
@import "settings/settings.fonts";

// TOOLS
//@import "tools/tools.rem";
@import "tools/tools.font-size";
@import "tools/tools.clearfix";
@import "tools/tools.hidden";

// third party
// media queries lib
@import "vendor/sass-mq/mq";

// GENERIC
@import "generic/generic.box-sizing";
@import "generic/generic.normalize";
@import "generic/generic.reset";
//@import "generic/generic.shared";

// ELEMENTS
//@import "elements/elements.page";
@import "elements/elements.headings";
@import "elements/elements.images";
//@import "elements/elements.tables";

// responsive font sizes
@include mq($until: md) {
  html {
    font-size: 12px;
  }
}

@include mq($until: md) {
  html {
    font-size: 11px;
  }
}

@include mq($until: sm) {
  html {
    font-size: 10px;
  }
}

body {
  overflow-x: hidden;
}

span {
  display: block;
}

ul {
  list-style: none;
}

nav ul {
  list-style: none;
}

nav li {
  float: left;
  margin: 0px 10px;
}

hr {
  border: 0;
  height: 1px;
  background: $primary;
  margin: 10px 0px;
}

a {
  color: $primary;
  text-decoration: none;
  display: block;
}

a:active,
a:focus {
  outline: none;
}

.warning {
  color: darkorange;
}

.valid {
  color: green;
}

form {
  .validation-states {
    pointer-events: none;
    position: absolute;
    height: 10px;
    top: 2rem;
    right: 2.5rem;
  }

  .error {
    color: darkred;
  }

  .error-checkbox {
    color: darkorange;
    font-weight: bold;
  }

  .textarea.error {
    position: relative !important;
    top: -3rem;
    right: 1rem;
    float: right;
  }

  .textarea.valid {
    position: relative !important;
    top: -3rem;
    float: none;
    clear: both;
  }

  .warning {
    color: darkorange;
  }

  .valid {
    color: green;
  }

  .valid:after {
    float: right;
    clear: both;
    position: absolute;
    content: "";
    background: url(./assets/icons/icon-check.svg) no-repeat center center;
    background-size: 2rem;
    width: 2rem;
    height: 2rem;
    right: 0rem;
    top: -0.5rem;
  }

  .search:after {
    float: right;
    clear: both;
    position: absolute;
    content: "";
    background: url(./assets/icons/icon-search.svg) no-repeat center center;
    background-size: 2rem;
    width: 2rem;
    height: 2rem;
    right: 0rem;
    top: -0.5rem;
  }

  .asyncValidating:after {
    content: "";
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 200;
    height: 16px;
    width: 16px;
    background-image: url(data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C…tlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==);
  }

  // media queries
  @include mq($until: sm) {
    .validation-states {
      top: 2rem;
    }
  }

  @include mq($from: sm, $until: md) {
    .validation-states {
      top: 1.8rem;
    }
  }

  @include mq($from: md) {
    .validation-states {
      top: 1rem;
    }
    .error {
      font-size: 0.8rem;
    }
  }
}

input,
select {
  min-height: 45px;
  line-height: 45px;
  width: 100%;
  border: 2px solid #999;
  border-radius: 4px;
  padding-left: 6px;
}

input:not([type="checkbox"]):focus {
  outline-color: $focusedinput;
  border-color: $focusedinput;
  box-shadow: 0px 0px 3px $focusedinput;
}

input[type="checkbox"] {
  outline: none;
  width: 2rem;
}

input:disabled {
  background: #ccc;
}

textarea {
  width: 100%;
}

// OBJECTS
//
@import "objects/objects.box";
@import "objects.grid";
@import "objects.modal";

.button {
  min-height: 4rem;
  line-height: 4rem;
  text-align: center;
  cursor: pointer;
  margin: 15px;
  padding: 0px 10px 0px 10px;
  display: block;
  float: left;
  border-radius: $global-radius;
  border: 1px solid;
  border-color: $primary;
  color: $secondary;
  background: $primary;
  display: inline-block;
  text-decoration: none;
  min-width: 120px;
}

.mini-button {
  min-height: 2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  float: left;
  border-radius: $global-radius;
  border: 1px solid;
  border-color: $primary;
  color: $secondary;
  background: $primary;
  display: block;
  text-decoration: none;
  min-width: 100%;
  min-height: 100%;
}

.button--disabled {
  pointer-events: none;
  background: $darkgrey;
  color: $secondary;
  a {
    color: $secondary;
  }
}

.button-primary {
  background: $primary;
  color: $secondary;
  a {
    color: $secondary;
  }
}

.button-primary--disabled {
  pointer-events: none;
  background: $darkgrey;
  color: $secondary;
  a {
    color: $secondary;
  }
}

.button-secondary {
  background: $secondary;
  color: $primary;
  a {
    color: $primary;
  }
}

.button-tertiary {
  background: $background;
  border: 1px solid $primary;
  color: $primary;
  a {
    color: $primary;
  }
}

.button-tertiary--disabled,
.button-tertiary:disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 20%;
  color: $lightgrey;
  border: 1px solid $lightgrey;
  a {
    color: $lightgrey;
  }
}

.button-critical-action {
  background: $red;
  color: $secondary;
  a {
    color: $secondary;
  }
}

.no-button {
  min-height: 4rem;
  line-height: 4rem;
  text-align: center;
  margin: 15px;
  float: left;
  display: inline-block;
}

.no-button-secondary {
  color: $secondary;
}

a .no-button {
  cursor: pointer;
}

.clickable {
  pointer-events: all;
  cursor: pointer;
}

.hr--footer {
  margin-top: 50px;
}

.hr--secondary {
  border: 1px solid white;
}

// COMPONENTS
//
// Build and include your project’s UI Compoments here.
//@import "components/example.components.buttons";

@import "components.table.scss";
@import "components/components.workspace";
@import "../../src/commonjs/components/components.product-group-rights";
@import "../../src/licensing/welcome/components.welcome";
@import "../../src/licensing/information/components.information";
@import "../../src/licensing/information/components.faq";
@import "../../src/licensing/contact/components.contact";
@import "../../src/licensing/contact/components.contact-information";
@import "../../src/licensing/accounts/components.accounts.scss";
@import "../../src/licensing/orders/modals/components.modal-order-details";
@import "../../src/licensing/orders/order_process/components.order-process.scss";
@import "../../src/licensing/orders/order_statistics/components.table-volumes.scss";
@import "../../src/licensing/orders/order_statistics/components.order-history.scss";
@import "../../src/licensing/orders/order_statistics/components.statistics.scss";
@import "../../src/licensing/orders/order_statistics/components.registrations.scss";
@import "../../src/psa_terms/components.terms-of-use";

@import "../../src/licensing/orders/components.orders.scss";
@import "../../src/licensing/distribution/components.distributions";
@import "../../src/layout/components.header-and-nav";
@import "../../src/layout/components.layout";


.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.cube1,
.cube2 {
  background-color: #333;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

// box
.c-box:after {
  content: "-->";
  display: block;
  clear: both;
  width: 100px;
  background: red;
  bottom: 15px;
  height: 10px;
  position: absolute;
}

.c-box--primary {
  background: $primary;
  color: $secondary;

  a {
    color: $secondary;
  }
  a:hover {
    text-decoration: underline;
  }
}

.c-box--secondary {
  background: $secondary;
  color: $primary;
  a {
    color: $primary;
  }
  a:hover {
    text-decoration: underline;
  }
}

.account-box {
  width: 100%;
  font-size: 10px;
  line-height: 1.2;
  border: 1px solid $primary;
  min-height: 40px;
  padding: 5px;
  border-radius: $global-radius;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-box-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-box__userId {
  border-right: 1px solid black;
  min-height: 38px;
  padding: 5px;
  width: 50%;
}

.account-box__screenId {
  line-height: 38px;
  padding-right: 5px;
}

@include mq($until: sm) {
  .account-box {
    font-size: 9px;
  }
}

// NProgress
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: $secondary;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px $secondary, 0 0 5px $secondary;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: -60px;
  right: 30px;
}

#nprogress .spinner-icon {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: $secondary;
  border-left-color: $secondary;
  border-radius: 50%;

  margin: 0 auto;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// UTILITIES
@import "utilities/utilities.clearfix";

//$inuit-offsets: true;
//@import "utilities/utilities.widths";
//@import "utilities/utilities.headings";
//@import "utilities/utilities.spacing";
//@import "utilities/utilities.print";
//@import "utilities/utilities.hide";

// add breakpoints to css for queries from the document
body:before {
  display: none;
  @include mq(xs) {
    content: "xs";
  }
  @include mq(sm) {
    content: "sm";
  }
  @include mq(md) {
    content: "md";
  }
  @include mq(lg) {
    content: "lg";
  }
  @include mq(xl) {
    content: "xl";
  }
  @include mq(xxl) {
    content: "xxl";
  }
}

/* Themes
*/

body.theme {
  background: $background !important;
  color: $primary !important;
  font-family: "Open Sans", sans-serif;
}

body.invert {
  color: white !important;

  a {
    color: white !important;
  }

  .language-switch {
    background: transparent;
  }

  .icon-languages:before {
    background: url(./assets/icons/icon-language-start.svg);
  }
}

// theme images
.bg-order {
  background: url(./assets/images/image-order.jpg);
}
.bg-ticker {
  background: url(./assets/images/image-ticker.jpg);
}
.bg-statistics {
  background: url(./assets/images/image-statistics.jpg);
}
.bg-statistics-admin {
  background: url(./assets/images/image-statistics-admin.jpg);
}

.bg {
  background-size: cover;
}

.bg-login {
  background: url(./assets/images/image-login.jpg);
  width: 100vw;
  position: fixed;
  z-index: -2;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-size: cover;
}

@include mq($from: lg) {
  .bg-login {
    height: 100vh;
  }
}

@include mq($from: xl) {
  .bg-login {
    height: 100vh;
  }
}

/* Trumps (itcss) 
*/

.vertical-spacing {
  margin-bottom: $vertical-rythm;
}

.vertical-spacing-half {
  // for "calc" see https://sass-lang.com/documentation/breaking-changes/slash-div/
  margin-bottom: calc($vertical-rythm / 2);
}

.vert-sm {
  min-height: $vertical-rythm * 6;
}

.vert-md {
  min-height: $vertical-rythm * 12 + $vertical-rythm;
}

.vert-lg {
  min-height: $vertical-rythm * 15 + $vertical-rythm;
}

.half-transparent {
  opacity: 0.5 !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.flush {
  padding: 0;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-error {
  color: $error !important;
  > * {
    color: $error !important;
  }
}

.vw100 {
  width: 100vw;
}

.padding {
  padding: $vertical-rythm;
}

@include mq($from: sm) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: inherit;
  }
}

@include mq($until: sm) {
  .mobile-only {
    display: inherit;
  }

  .desktop-only {
    display: none;
  }
}

h2.warning {
  color: darkorange;
}
