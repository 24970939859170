.faq hr {
	border: 0;
    height: 1px;
    background: white;
}

.faq__entry header {
	font-weight: bold;
}

.faq__entry ul {
	padding-top: 20px;
	list-style-type: square;
	list-style-position: inside;
	padding-left: 1.5em;
	text-indent: -1.5em;
}