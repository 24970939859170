.workspace {
  h1 {
    font-size: 1.5em;
    margin-bottom: 0em;
    padding-bottom: 0em;
  }

  .workspace-header {
    margin-bottom: 1em;
    h1 {
      font-size: 2.625rem;
    }
  }
  .workspace-body {
    clear: both;
  }

  section {
    clear: both;
  }
}

// highlighting, when rows get added to a table
@keyframes highlight {
  0% {
    background-color: $focusedinput;
  }
  100% {
    background-color: none;
  }
}

tr.highlight,
h1.highlight {
  animation-name: highlight;
  animation-duration: 5s;
}
