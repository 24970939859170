.volumes {
  
  tbody {
    tr:nth-child(even) {
      td>div:first-of-type { background: white; padding:5px; }
    }
    tr:nth-child(odd) {
      td>div:first-of-type { background: gray; padding:5px; }
    }
    td    {
      padding: 0px 1px 2px 0px;
      > div {
        height: 50px;
      }
    }
}

// media queries
 
  .volumes__table {
    overflow-x: scroll;
    margin-bottom: -18px;
    
    table {
      border-top: 1px solid $primary;
      border-bottom: 3px solid $primary;
    }
  }
    
  thead {
    float: left;
    
    th {
      text-align: left;
      display: block;
    }
    
  }
  
  tbody {
    float: left;

    tr {
      display: table-cell;
    }
    td {
      min-width: 50px;
      float: left;
      clear: both;
    }  
    
  }
  

}