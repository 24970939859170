//TODO: unified style of form fields (font especially)

.order-process-description {
  line-height: 1.5rem;
  margin: 1rem 3rem 2rem 3rem;
}

.react-tabs__tab--selected {
  color: $primary;
}

.orderSummary {
  .checkout-warning {
    margin-top: 2em;
    padding: 1.5em;
    font-size: 1.2em;
    line-height: 1.2em;
    border: 1px solid $warning;

    p {
      margin-bottom: 0.5em;
    }
  }
  .header {
    width: 100%;
  }

  .button {
    display: table;
  }

  .line-active {
    color: green;
    border-bottom: 3px solid green;
  }

  .line-inactive {
    color: gray;
    border-bottom: 3px solid gray;
    border-bottom-style: dotted;
  }

  .grid {
    margin: 0px;
    .grid__item {
      padding: 0px;
    }
  }

  .failed {
    color: red;
  }
}

.nobr {
  white-space: nowrap;
}

.orderProcess {
  .label-input {
    min-height: 35px;
    line-height: 35px;
  }
}

.info-modal-icon {
  margin: auto;
  margin-top: 10px;
  width: 30px;
  height: 22px;
  background: url(./assets/icons/icon-info-modal.png);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.info-modal-icon-non-standard-row {
  margin: auto;
  width: 30px;
  height: 22px;
  background: url(./assets/icons/icon-info-modal.png);
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-details {
  margin: auto;
  width: 40px;
  height: 40px;
  background: url(./assets/icons/icon-visible.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-rotate-left {
  margin: auto;
  margin-top: 10px;
  width: 24px;
  height: 24px;
  background: url(./assets/icons/icon-rotate-left.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
}

.icon-exclamation-triangle {
  margin: auto;
  margin-top: 10px;
  width: 24px;
  height: 24px;
  // background: url(./assets/icons/icon-exclamation-triangle) no-repea.svgt;
  background-size: contain;
  cursor: pointer;
}

.tooltip-info {
  color: $primary !important;
  background-color: white !important;
  border: 1px solid $primary !important;
  font-size: 1rem;
}

.tooltip-warning {
  color: $error !important;
  background-color: white !important;
  border: 2px solid $error !important;
  font-size: 1rem;
}

// NonStandardProducts

.nonStandardProducts {
  // background: grey;
  margin: 10px 0;

  .tr,
  .tr-head {
    display: grid;
    // when changing the template columns, adapt ms-grid-columns below (needed for IE11)
    grid-template-columns: 40px 50px 400px repeat(4, 1fr);
  }

  .tr-head {
    font-weight: bold;
    text-transform: uppercase;
  }

  .tr {
    margin: 5px 0;
    padding: 5px;
  }

  .tbody {
    border-top: 2px solid black;

    > div:first-child {
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .td,
  .th {
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(1) {
      -ms-grid-column: 1;
      grid-column: 1;
    }
    &:nth-child(2) {
      -ms-grid-column: 2;
      grid-column: 2;
    }
    &:nth-child(3) {
      -ms-grid-column: 3;
      grid-column: 3;
    }
    &:nth-child(4) {
      -ms-grid-column: 4;
      grid-column: 4;
    }
    &:nth-child(5) {
      -ms-grid-column: 5;
      grid-column: 5;
    }
    &:nth-child(6) {
      -ms-grid-column: 6;
      grid-column: 6;
    }
    &:nth-child(7) {
      -ms-grid-column: 7;
      grid-column: 7;
    }
  }

  .td {
    background-color: #ebebeb;
    margin: 1px;
    padding: 2px;
  }

  .icon-minus {
    margin: 0 auto;
    margin-left: 6px;
    width: 28px;
    height: 28px;
    background: url(./assets/icons/icon-minus.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .icon-unfold-inverted {
    width: 36px;
    height: 36px;
    background: url(./assets/icons/icon-unfold-inverted.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .icon-fold-inverted {
    width: 36px;
    height: 36px;
    background: url(./assets/icons/icon-fold-inverted.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .attention {
    color: white;
    background: $error;
    text-align: center;
    padding: 10px;
    border-radius: 50%;
    margin-left: 10px;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 15px;
  }

  .numLabels {
    min-height: 0px;
    line-height: 18px;
    width: 50%;
  }

  .Select-control {
    background-color: #ebebeb;
    border: 0;
    width: 400px;
  }

  .Select-value-label {
    color: $primary !important;
  }

  .Select-multi-value-wrapper { 
    white-space: nowrap; 
    overflow-x: scroll;
  }

  .formWrapper {
    grid-row: 3;
    grid-column: 1 / span 7;
    color: $primary;
    background-color: #b5b5b5;
    height: inherit;

    .extraServicesHeader {
      color: white;
      background-color: $primary;
    }

    .form-open-close-message {
      > span:nth-child(1) {
        display: inline-block;
        vertical-align: middle;
      }
      > span:nth-child(2) {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .form-error-message-header {
      color: $error;
      font-weight: bold;
      padding: 0.5rem;
    }

    .form-error-message {
      color: $error;
      font-weight: bold;
    }

    select {
      background-color: white;
      padding-left: 4px;
    }

    select option {
      color: #000;
    }

    select option[value=""] {
      color: #757575;
    }

    input {
      color: $primary;
    }

    input[type="checkbox"] {
      min-height: 0px;
    }
    option {
      min-height: 45px;
    }

    img {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    p {
      margin: 0.5rem;
      line-height: 1.5rem;
    }

    .confirm-checkbox-label {
      display: inline-block;
      padding-left: 2.5rem;
      text-indent: -2.5rem;
      line-height: 1.5rem;
      font-weight: bold;
    }

    .confirm-checkbox-label--disabled {
      color: #888;
    }

    .confirm-checkbox-input {
      width: 1.5rem;
      height: 1.5rem;
      min-height: 1.5rem;
      line-height: 1.5rem;
      margin-right: 0.5rem;
      vertical-align: middle;
      position: relative;
    }
  }
}

.indexedFormField {
  > div:nth-child(1) {
    margin-left: 0.5rem;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .formFieldWrapper {
    min-height: 45px;
    line-height: 45px;
    border: 2px solid #999;
    border-radius: 4px;
    padding-left: 6px;
    background-color: white;
    color: $primary;
  }

  .cell {
    padding-left: 0;
    cursor: pointer;
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .buttonAddProduct {
    margin-left: 5px;
  }
}

.acknowledge-warning{ 
  padding-left: 1rem;
  
  label {
    width: 15rem; 
    display: inline-block;
    line-height: 1.5rem;
  }
  input {
    width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    line-height: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: top;
    position: relative;
  } 
  
}
