.information {
  .document-entry:before {
    background: url(./assets/icons/icon-manual.svg) no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    content: "";
  }

  .document-entry {
    background-color: $secondary;
    margin-bottom: 1rem;
    line-height: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .information-editor {
    margin-top: 2rem;
  }

  .information-editor__header {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .button {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-height: 3rem;
    line-height: 3rem;
  }

  .file-input {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .file-input__input {
    flex: 0 0 auto;
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  .file-input__input input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  .file-input__filename {
    flex: 1 1 auto;
    margin-left: 0.5rem;
    padding: 0.5rem;
    background-color: $secondary;
    line-height: 2rem;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .file-input__upload {
    cursor: pointer;
    color: $primary;
  }
  .file-input__upload--disabled {
    color: #c0c0c0;
  }
}
