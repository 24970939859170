tbody {
  border-top: 2px solid $primary;
  border-bottom: 2px solid $primary;

  tr:nth-child(even) {
    td > .cell:first-of-type {
      background: $darkgrey;
      padding: 5px;
    }
  }
  tr:nth-child(odd) {
    td > .cell:first-of-type {
      background: $lightgrey;
      padding: 5px;
    }
  }

  td {
    padding: 0px 1px 2px 0px;
    > div {
      height: 50px;
    }
  }
}

.modal-order-details {
  background: white;

  .cell {
    min-width: 120px;
    margin: 1px;
    line-height: 40px;
    padding-left: 20px;
  }

  @include mq($until: sm) {
    .cell {
      min-width: 120px !important;
    }
  }

  .table {
    overflow-x: scroll;
    overflow-y: hidden;
    border-top: 2px solid $primary;
    border-bottom: 2px solid $primary;
  }

  thead {
    position: absolute;

    th {
      text-align: left;
      display: block;
      height: 54px;
      padding: 20px 10px 10px 0px;
    }
  }

  tbody.order-positions {
    float: left;
    padding-left: 150px;
    border-top: none !important;
    border-bottom: none !important;

    tr {
      display: table-cell;
    }
    td {
      min-width: 50px;
      float: left;
      clear: both;
    }
  }

  @include mq($until: md) {
    tbody {
      padding-left: 100px;
    }
  }

  .currency {
    text-align: right;
  }

  .total {
    font-weight: bold;
  }

  .tooltip-info {
    color: $primary !important;
    background-color: white !important;
    border: 1px solid $primary !important;
    font-size: 1rem;
  }

  .__react_component_tooltip .multi-line {
    text-align: left;
  }
}
