/*------------------------------------*\
    #GRID (SASS-MQ)
\*------------------------------------*/

$susy-columns: 12;
$susy-gutter-width: 15px;
$susy: (
  columns: $susy-columns,
  gutter-position: inside-static,
  column-width: 0px,
  container: 1200px,
  gutter: $susy-gutter-width*2
);

// i hope to get rid of this as not much is used, laters
@import "vendor/susy/sass/susy";

.#{$namespace}container {
  @include container;
  padding: 0px 15px;
}

// this is what a "row" on bootstrap would be
.#{$namespace}grid {
  @include break;
  @include susy-clearfix;
  margin-left: -$susy-gutter-width;
  margin-right: -$susy-gutter-width;
}

// this is what a "column" on bootstrap would be
.#{$namespace}grid__item {
  box-sizing: border-box;
  position: relative;
  float: left;
  padding-left: $susy-gutter-width;
  padding-right: $susy-gutter-width;
}

// usage: layoutItem((xs: 12, sm: 6, lg: 3))`
@mixin layoutItem($deviceList) {
  @each $breakpoint, $width in $deviceList {
    @if ($breakpoint == xs) {
      width: span($width of $susy-columns);
    } @else {
      @include mq($from: $breakpoint) {
        width: span($width of $susy-columns);
      }
    }
  }
}

// !can be put into a loop for every breakpoint
.#{$namespace}full {
  @include layoutItem((xs: 12));
}

.#{$namespace}half {
  @include layoutItem((xs: 6));
}

.#{$namespace}quarter {
  @include layoutItem((xs: 3));
}

.#{$namespace}sm-and-up-full {
  @include layoutItem((sm: 12));
}

.#{$namespace}sm-and-up-1half {
  @include layoutItem((sm: 6));
}

.#{$namespace}sm-and-up-1quarter {
  @include layoutItem((sm: 3));
}

.#{$namespace}sm-and-up-1third {
  @include layoutItem((sm: 4));
}

.#{$namespace}sm-and-up-2third {
  @include layoutItem((sm: 8));
}

.#{$namespace}md-and-up-full {
  @include layoutItem((md: 12));
}

.#{$namespace}md-and-up-1half {
  @include layoutItem((md: 6));
}

.#{$namespace}md-and-up-1quarter {
  @include layoutItem((md: 3));
}

.#{$namespace}md-and-up-1third {
  @include layoutItem((md: 4));
}

.#{$namespace}md-and-up-2third {
  @include layoutItem((md: 8));
}

/*BOOTSTRAP LIKE COLUMNS*/
@mixin make-bootstrap-breakpoint-classes($class) {
  @for $i from 1 through $susy-columns {
    .#{$namespace}#{$class}-#{$i} {
      width: span($i of $susy-columns);
    }
  }
}

@mixin make-bootstrap-classes() {
  @include make-bootstrap-breakpoint-classes("xs");

  @include mq($from: xs) {
    @include make-bootstrap-breakpoint-classes("sm");
  }

  @include mq($from: sm) {
    @include make-bootstrap-breakpoint-classes("md");
  }

  @include mq($from: md) {
    @include make-bootstrap-breakpoint-classes("lg");
  }
}

@include make-bootstrap-classes();

/* PUSH PULL*/
@mixin push-pull-classes($class) {
  @for $i from 1 through $susy-columns {
    .#{$namespace}#{$class}-pull-#{$i} {
      margin-right: span($i of $susy-columns);
    }
  }

  @for $i from 1 through $susy-columns {
    .#{$namespace}#{$class}-push-#{$i} {
      margin-left: span($i of $susy-columns);
    }
  }
}

// NOTE: does not work with dart-sass (node-sass accepted it!)
// However, as we don't use this classes in the application, just comment them out

// @mixin generate-push-pull-classes() {
//   @include push-pull-classes("xs");

//   @include mq($from: sm) {
//     @include push-pull-classes("sm");
//   }

//   @include mq($from: md) {
//     @include push-pull-classes("md");
//   }

//   @include mq($from: lg) {
//     @include push-pull-classes("lg");
//   }
// }

// @include generate-push-pull-classes() .DEBUG {
//   & .#{$namespace}container {
//     @include show-grid(12);

//     & .#{$namespace}grid__item {
//       background: rgba(150, 0, 100, 0.6);
//     }

//     & .#{$namespace}grid__item > * {
//       background: rgba(15, 0, 100, 0.2);
//     }
//   }
// }
