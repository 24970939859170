.product-group-table {
  width: auto;
  line-height: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  tbody {
    border: none;
  }
  button {
    margin-left: 0;
  }
}
