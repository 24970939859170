.footer a {
  display: inline;
}

// news

.news-ticker {
  header {
    padding-left: $vertical-rythm*2;
    padding-top: $vertical-rythm;
    padding-bottom: $vertical-rythm;
    color: white;
    font-weight: bold;
  }

  button {
    display: inline-block;
    margin-top: $vertical-rythm;
    margin-left: $vertical-rythm*2;
  }
}

.news-ticker {
  display: inline-block;
  width: 100%;
}

.news-ticker__text {
  font-size: 3rem;
}

.news-ticker__text-bg {
  border-left: 10px solid red;
  height: 60px;
  background: rgba(255, 255, 255, 0.5);
  line-height: 60px;
  font-size: 60px;
}

.news-ticker__text:after {
  content: "";
  width: 0;
  position: absolute;
  top: 30%;
  right: 15px;
  float: right;
  height: 0;
  border-top: 37px solid transparent;
  border-bottom: 37px solid transparent;
  border-right: 37px solid red;
}

.version-test {
  background-color: red;
  color: white;
  font-weight: bold;
}
