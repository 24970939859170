.filter-panel {
  padding-bottom: 1em;
  z-index: 500;
  input,
  select {
    min-height: 32px;
    height: 32px;
  }
}

.Select-menu-outer {
  z-index: 1000;
}
.Select-input {
  input {
    width: 100%;
  }
}

.map {
  input[type="radio"],
  input[type="checkbox"] {
    min-height: 1.2em;
    line-height: 1.2em;
  }
  .zeroPaddedCheckBox {
    padding: 0px;
  }
  .selectAll {
    color: black;
    padding: 0px;
  }
  .secondLi {
    padding-top: 2px;
  }

  .selectAllList {
    border-bottom: 1px solid grey;
    padding-bottom: 2px;
  }

  .mapLegend {
    width: 200px;
    margin: 0px;
    background-clip: padding-box;
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    list-style: none;
    padding: 10px;
    li {
      margin: 0px;
    }
  }

  .radioRoot {
    padding: 0px;
  }
  .description {
    padding-left: 4px;
  }
  .MapControlContainer {
    height: 44px;
    width: 44px;
    padding: 8px;
    margin: 0px;
    background-clip: padding-box;
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
}

.SVGPieChart {
  position: relative;
  height: 100%;

  svg {
    transform: rotate(-90deg);
    z-index: 1 !important;
  }

  p {
    color: white;
    font-weight: bold;
    z-index: 2;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
  }
}

.mapLinks {
  clear: both;
  height: 1em;
  span {
    float: left;
  }

  a {
    display: block;
    color: black;
    height: 16px;
    text-decoration: none;
  }
}

.map-popup-table {
  tbody {
    border: none;
  }
}
